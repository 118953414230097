// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaymentItem } from '@ocx/data-payment-provider';
import { CarWashInput } from '@ocx/graphql';
import { IStoreCarWashOption } from '../../../../modules/stores/types';
import { applePayPaymentInstrument, googlePayPaymentInstrument } from '../../../../modules/wallet/wallet.constants';
import { PAYMENT_INSTRUMENT_TYPE } from '../../../../modules/wallet/wallet.types';

export const buildCarWashInput = (option: IStoreCarWashOption | null): CarWashInput | null => {
  let carWashInput: CarWashInput | null = null;

  if (option && option.productCode && option.posCodeModifier && option.posCode) {
    carWashInput = {
      productCode: option.productCode,
      posCode: option.posCode,
      posCodeModifier: option.posCodeModifier,
      amount: 1, // TODO find out the purpose of the field
    };
  }
  return carWashInput;
};

export interface IGetSelectedPaymentMethodParams {
  selectedId: string | null;
}

export const getSelectedPaymentInstrumentType = ({
  selectedId,
}: IGetSelectedPaymentMethodParams): PAYMENT_INSTRUMENT_TYPE | null => {
  if (selectedId === googlePayPaymentInstrument.uuid) {
    return PAYMENT_INSTRUMENT_TYPE.GOOGLE_PAY;
  }

  if (selectedId === applePayPaymentInstrument.uuid) {
    return PAYMENT_INSTRUMENT_TYPE.APPLE_PAY;
  }

  if (selectedId !== null) {
    return PAYMENT_INSTRUMENT_TYPE.CARD;
  }

  return null;
};

type GeneratePaymentItemsParams = {
  selectedCarWashOption?: IStoreCarWashOption | null;
};

export const buildPaymentItems = (params: GeneratePaymentItemsParams): PaymentItem[] => {
  const { selectedCarWashOption = null } = params;

  const items: PaymentItem[] = [
    {
      label: 'FUEL (TBD)',
      price: '1',
      status: 'PENDING',
    },
  ];
  if (selectedCarWashOption !== null) {
    items.push({
      label: `Car wash: ${selectedCarWashOption.name}`,
      price: selectedCarWashOption.unitPrice.toString(),
      status: 'FINAL',
    });
  }
  return items;
};
