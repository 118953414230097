import React, { useCallback } from 'react';

import { useShowGraphqlUserVisibleErrorSnackbar } from '@ocx-app/hooks/use-show-graphql-user-visible-error-snackbar.hook';
import { ScreenLoader } from '@ocx/ui';

import { SelectPump } from './SelectPump';
import { SelectPumpLayout } from './SelectPumpLayout';
import { useSelectPumpController } from './hooks/useSelectPumpController';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { applePayPaymentInstrument, googlePayPaymentInstrument } from '../../../modules/wallet/wallet.constants';
import { PAYMENT_INSTRUMENT_TYPE } from '../../../modules/wallet/wallet.types';
import { useHandleAddPaymentMethodClick } from './hooks/useHandleAddPaymentMethodClick';
import { ReplaceToHome } from '../../Home/ReplaceToHome';

export const SelectPumpController: React.FC = React.memo(() => {
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const showGraphqlUserVisibleErrorSnackbar = useShowGraphqlUserVisibleErrorSnackbar();

  const actions = useGetNavigationActions();
  const onSiteLoadFailed = () => {
    showGenericErrorSnackbar();
    actions.pushToHome();
  };

  const {
    loading,
    store,
    selectPump,
    deselectPump,
    selectedPump,
    shouldPrintReceipt,
    togglePrintReceipt,
    handlePurchase,
    carWashListController,
    paymentInstrumentsListSelector,
    isPaymentInstrumentsLoading,
    selectedPaymentInstrumentType,
    isCardsAvailable,
    isWalletSizeLimitReached,
  } = useSelectPumpController({
    onSiteLoadFailed,
    onError: showGraphqlUserVisibleErrorSnackbar,
  });

  const handleAddPaymentMethodClick = useHandleAddPaymentMethodClick({
    isWalletSizeLimitReached,
    isGoToWalletButtonShown: true,
  });

  const handlePurchaseGooglePay = useCallback(
    () => handlePurchase(PAYMENT_INSTRUMENT_TYPE.GOOGLE_PAY, googlePayPaymentInstrument.uuid),
    [handlePurchase],
  );
  const handlePurchaseApplePay = useCallback(
    () => handlePurchase(PAYMENT_INSTRUMENT_TYPE.APPLE_PAY, applePayPaymentInstrument.uuid),
    [handlePurchase],
  );
  const handlePurchaseCard = useCallback(() => {
    if (paymentInstrumentsListSelector.selectedListItem) {
      handlePurchase(PAYMENT_INSTRUMENT_TYPE.CARD, paymentInstrumentsListSelector.selectedListItem.uuid);
    }
  }, [handlePurchase, paymentInstrumentsListSelector.selectedListItem]);

  if (loading && !store) {
    return (
      <SelectPumpLayout>
        <ScreenLoader />
      </SelectPumpLayout>
    );
  }

  if (!store) {
    return <ReplaceToHome />;
  }

  return (
    <SelectPumpLayout>
      <SelectPump
        addressLine1={store.address.addressLine1}
        addressLine2={store.address.addressLine2}
        pumps={store.pumps}
        carWashListController={carWashListController}
        paymentInstrumentsListSelector={paymentInstrumentsListSelector}
        isPaymentInstrumentsLoading={isPaymentInstrumentsLoading}
        selectPump={selectPump}
        deselectPump={deselectPump}
        selectedPump={selectedPump}
        shouldPrintReceipt={shouldPrintReceipt}
        togglePrintReceipt={togglePrintReceipt}
        handlePurchaseGooglePay={handlePurchaseGooglePay}
        handlePurchaseApplePay={handlePurchaseApplePay}
        onAddPaymentMethodClick={handleAddPaymentMethodClick}
        handlePurchaseCard={handlePurchaseCard}
        selectedPaymentInstrumentType={selectedPaymentInstrumentType}
        isCardsAvailable={isCardsAvailable}
      />
    </SelectPumpLayout>
  );
});
