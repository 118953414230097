import { CircularProgress, Box, ButtonBase } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { PointsCoins, DollarsCoins } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../../../theme/constants';
import { formatPrice } from '../../../../lib/formatters/formatPrice';

interface IPointsProps {
  points: number;
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
  loading?: boolean;
  onClick?(): void;
}

export const Points = (props: IPointsProps) => {
  const { points, pointsStrategy, loading, onClick = () => {} } = props;

  return (
    <ButtonBase
      sx={{ borderRadius: '16px', position: 'relative', px: SizingMultiplier.md, flexShrink: 0 }}
      onClick={onClick}>
      <Box display="flex" alignItems="center" sx={{ opacity: loading ? 0.1 : 1 }}>
        <Typography variant="h6" component="span" mr={SizingMultiplier.xs}>
          {pointsStrategy === PointsStrategy.Cents && formatPrice(points)}
          {pointsStrategy === PointsStrategy.Points && (
            <FormattedMessage
              id="loyalty:pointsStrategy:points:title"
              defaultMessage="{points} pts"
              description="Points title under the points number for points strategy POINTS"
              values={{ points }}
            />
          )}
        </Typography>
        {pointsStrategy === PointsStrategy.Cents && <DollarsCoins width={34} height={34} />}
        {pointsStrategy === PointsStrategy.Points && <PointsCoins width={34} height={34} />}
      </Box>
      {loading && (
        <Box position="absolute" display="flex" top={0} bottom={0} left={0} right={0}>
          <CircularProgress size={24} color="primary" sx={{ m: 'auto' }} />
        </Box>
      )}
    </ButtonBase>
  );
};
