import { ParPayTokenizationSession } from '@ocx/graphql';

import { PaymentProviderIFrameBasedInterface } from '../payment-provider-iframe-based';
import { ParPaySessionGetter } from './parpay-payment-provider.types';
import {
  PaymentProviderIFrameBasedAddEventListenersParams,
  PaymentProviderIFrameBasedRenderParams,
  PaymentProviderIFrameBasedSubmitParams,
} from '../payment-provider-iframe-based/payment-provider-iframe-based.types';

export class ParPayPaymentProvider implements PaymentProviderIFrameBasedInterface {
  public tokenizationMechanism = 'IFRAME' as const;

  private getParPaySession: ParPaySessionGetter;
  private parPaySession: ParPayTokenizationSession | null = null;
  private eventHandler: ((event: MessageEvent) => void) | null = null;

  constructor(params: { getParPaySession: ParPaySessionGetter }) {
    this.getParPaySession = params.getParPaySession;
  }

  async render(params: PaymentProviderIFrameBasedRenderParams) {
    const { containerId, iFrameId } = params;

    const container = document.getElementById(containerId);
    if (!container) {
      throw new Error(`[ParPayPaymentProvider] Container with id ${containerId} not found`);
    }

    const existingIframe = document.getElementById(iFrameId);
    if (existingIframe) {
      existingIframe.parentNode?.removeChild(existingIframe);
    }

    const frame = document.createElement('iframe');
    frame.id = iFrameId;
    // TODO add iFrame styles

    const parPaySession = await this.getParPaySession();
    frame.src = parPaySession.iframeUrl;
    container.appendChild(frame);

    this.parPaySession = parPaySession;
  }

  async addEventListeners(params: PaymentProviderIFrameBasedAddEventListenersParams) {
    if (this.eventHandler) {
      window.removeEventListener('message', this.eventHandler);
      this.eventHandler = null;
    }

    this.eventHandler = (event: MessageEvent) => {
      this.handleIFrameMessage({
        event,
        iFrameId: params.iFrameId,
        onPaymentInstrumentTokenized: params.onPaymentInstrumentTokenized,
        onSubmitError: params.onSubmitError,
        onValidationError: params.onValidationError,
      });
    };
    window.addEventListener('message', this.eventHandler);
  }

  async submit(params: PaymentProviderIFrameBasedSubmitParams): Promise<void> {
    if (!this.parPaySession) {
      throw new Error(`[ParPayPaymentProvider] ParPay session not initialized`);
    }

    const { iFrameId } = params;

    const frame = document.getElementById(iFrameId) as HTMLIFrameElement | null;
    if (!frame) {
      throw new Error(`[ParPayPaymentProvider] iFrame with id ${iFrameId} not found`);
    }
    if (!frame.contentWindow) {
      throw new Error(`[ParPayPaymentProvider] iFrame.contentWindow not found`);
    }

    // post message into Iframe to start tokenization
    const iFrameURL = new URL(this.parPaySession.iframeUrl);
    const iFrameOrigin = iFrameURL.origin;
    frame.contentWindow.postMessage('aurus-token', iFrameOrigin);
  }

  private handleIFrameMessage(params: {
    iFrameId: string;
    event: MessageEvent;
    onPaymentInstrumentTokenized: PaymentProviderIFrameBasedAddEventListenersParams['onPaymentInstrumentTokenized'];
    onSubmitError: PaymentProviderIFrameBasedAddEventListenersParams['onSubmitError'];
    onValidationError: PaymentProviderIFrameBasedAddEventListenersParams['onValidationError'];
  }) {
    if (!this.parPaySession) {
      return params.onSubmitError('[ParPayPaymentProvider] ParPay session not initialized');
    }

    const iFrameURL = new URL(this.parPaySession.iframeUrl);
    if (iFrameURL.origin !== params.event.origin) {
      return params.onSubmitError('[ParPayPaymentProvider] Unknown message origin');
    }

    if (params.event.data.startsWith('fieldValidationStatus')) {
      const validation: {
        isValid: boolean;
        field?: 'cardNumber' | 'expMonth' | 'expYear' | 'cvv';
        errorType?: 'blank' | 'invalid';
        errorMsg?: string;
      } = JSON.parse(params.event.data.split('=')[1]);
      return params.onSubmitError(validation.errorMsg || 'Validation not passed');
    }

    if (params.event.data.startsWith('response')) {
      const response: {
        response_code: string;
        response_text: string;
        masked_card_num: string;
        card_type: string;
        card_expiry_date: string;
        one_time_token: string;
        card_holder_name: string;
      } = JSON.parse(params.event.data.split('=')[1]);
      return params.onPaymentInstrumentTokenized({
        oneTimeToken: response.one_time_token,
        maskedCardNumber: response.masked_card_num,
        cardType: response.card_type,
        cardExpirationDate: response.card_expiry_date,
      });
    }

    if (params.event.data.startsWith('iframeHeight')) {
      const value = params.event.data.split('=')[1];
      const iFrame = document.getElementById(params.iFrameId) as HTMLIFrameElement;
      if (!iFrame) {
        return params.onSubmitError(`[ParPayPaymentProvider] iFrame with id ${params.iFrameId} not found`);
      }
      iFrame.style.height = value;
      return undefined;
    }

    // TODO Unhandled events
    // data.startsWith('enablePlaceOrder')
    // data.startsWith('iframeLoaded')
    return undefined;
  }

  async getIsApplePayAvailable() {
    return false;
  }

  async getIsGooglePayAvailable() {
    return false;
  }
}
