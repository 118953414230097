import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';

export enum PaymentInstrumentOrder {
  Priority,
  Default,
  Disabled,
}

export type PaymentInstrument = {
  uuid: string;
  accountType: PaymentAccountType;
  paymentType: PaymentInstrumentType | null;
  accountNumber: string | null;
  address: {
    zipCode: string;
  } | null;
  last4: string | null;
  displayName: string | null;
  alias: string | null;
  providerNickName: string | null;
  balance?: number | null;
  isDefault: boolean;
  canBeUsedToPay: boolean;
  canBeDeleted: boolean;
  order: PaymentInstrumentOrder;
};

export enum PAYMENT_INSTRUMENT_TYPE {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  // TODO: rename to Credit and add Prepaid?
  // This seems to be used only to show correct button type in different scenarious
  // This might be needed to be extended, when custom behaviour for a gift card is introduced
  CARD = 'CARD', // works for both CREDIT and PREPAID
  UNKNOWN = 'UNKNOWN',
}
