import React, { ReactNode } from 'react';
import { Stack, SxProps } from '@mui/material';

import { PoweredByOpenCommerce, ScreenLoaderBackdrop } from '@ocx/ui';

export type AddCardIFrameBasedLayoutProps = {
  loading: boolean;
  children: ReactNode;
  sx?: SxProps;
};

export const AddCardIFrameBasedLayout = ({ loading, children, sx }: AddCardIFrameBasedLayoutProps) => (
  <>
    <ScreenLoaderBackdrop open={loading} />
    <Stack justifyContent="space-between" flexGrow="1" overflow="auto" sx={sx}>
      {children}
      <PoweredByOpenCommerce />
    </Stack>
  </>
);
