import { useBlurOverlayStylesWhenAppIsInactive } from '@ocx-app/components/blur/blur-overlay-when-app-is-inactive.component';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { buttonTextMessages } from '@ocx-app/modules/localization/button-text.messages';

import { logInDev } from '@ocx-app/lib/logger/logger';
import { useShowGenericErrorSnackbar } from '@ocx-app/hooks/useShowGenericErrorSnackbar';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  PaymentProviderIFrameBasedTokenizedPaymentInstrument,
  usePaymentProviderIFrameBased,
} from '@ocx/data-payment-provider';
import { Button } from '@ocx/ui';
import { PaymentAccountType, useCustomerPaymentInstrumentCreateMutation } from '@ocx/graphql';
import { CONTAINER_ID, IFRAME_ID } from './add-card-iframe-based.constants';
import { AddCardIFrameBasedLayout } from './add-card-iframe-based.layout';

type AddCardIframeBasedControllerProps = {
  onCardAdded(params: { paymentInstrumentId: string | null }): void;
};

export const AddCardIFrameBasedController = (props: AddCardIframeBasedControllerProps) => {
  const { onCardAdded } = props;
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const blurOverlayStyles = useBlurOverlayStylesWhenAppIsInactive();
  const paymentProvider = usePaymentProviderIFrameBased();
  const [createPaymentInstrument] = useCustomerPaymentInstrumentCreateMutation({
    refetchQueries: ['getPaymentInstruments'],
  });

  const handlePaymentInstrumentTokenized = useCallback(
    async (params: PaymentProviderIFrameBasedTokenizedPaymentInstrument) => {
      try {
        const { data } = await createPaymentInstrument({
          variables: {
            input: {
              accountType: PaymentAccountType.Credit,
              nonce: params.oneTimeToken,
            },
          },
        });
        if (!data) {
          throw new Error('[AddCardIFrameBasedController] createPaymentInstrument: no data');
        }
        onCardAdded({ paymentInstrumentId: data.customerPaymentInstrumentCreate.uuid });
      } catch (e) {
        logInDev(e);
        showGenericErrorSnackbar();
      }
    },
    [createPaymentInstrument, onCardAdded, showGenericErrorSnackbar],
  );

  const handleSubmitError = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('submit error');
  }, []);

  const handleValidationError = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('validation error');
  }, []);

  // Render IFrame
  useEffect(() => {
    paymentProvider
      .render({
        iFrameId: IFRAME_ID,
        containerId: CONTAINER_ID,
      })
      .catch((e) => {
        logInDev(e);
        // TODO close modal?
      });
  }, [paymentProvider]);

  // Add/update event listeners for the IFrame events
  useEffect(() => {
    paymentProvider.addEventListeners({
      iFrameId: IFRAME_ID,
      onPaymentInstrumentTokenized: handlePaymentInstrumentTokenized,
      onSubmitError: handleSubmitError,
      onValidationError: handleValidationError,
    });
  }, [paymentProvider, handlePaymentInstrumentTokenized, handleSubmitError, handleValidationError]);

  const handleSubmit = useCallback(() => {
    paymentProvider.submit({ iFrameId: IFRAME_ID }).catch(logInDev);
  }, [paymentProvider]);

  return (
    <AddCardIFrameBasedLayout loading={false} sx={blurOverlayStyles}>
      <div id={CONTAINER_ID} />
      <Button fullWidth onClick={handleSubmit}>
        <FormattedMessage {...buttonTextMessages['button-text:submit']} />
      </Button>
    </AddCardIFrameBasedLayout>
  );
};
