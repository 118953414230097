import { Box, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { GooglePayButton } from '@ocx/data-payment-provider';
import { Button } from '@ocx/ui';
import { PAYMENT_INSTRUMENT_TYPE, PaymentInstrument } from '../../../../../../modules/wallet/wallet.types';
import { ApplePayButton } from '../../../../../../components/ApplePayButton/ApplePayButton';
import {
  applePayPaymentInstrument,
  googlePayPaymentInstrument,
} from '../../../../../../modules/wallet/wallet.constants';
import { SizingMultiplier } from '../../../../../../theme/constants';

export interface IPayButtonProps {
  onGooglePayButtonClick(): void;
  onApplePayButtonClick(): void;
  onCardPayButtonClick(): void;
  selectedPaymentInstrumentType: PAYMENT_INSTRUMENT_TYPE | null;
  paymentInstruments: PaymentInstrument[];
  isListMode: boolean;
}

export const PayButton: React.FC<IPayButtonProps> = ({
  onGooglePayButtonClick,
  onApplePayButtonClick,
  onCardPayButtonClick,
  selectedPaymentInstrumentType,
  paymentInstruments,
  // display available apple/google pay buttons as a list
  isListMode,
}) => {
  const theme = useTheme();

  if (isListMode) {
    return (
      <>
        {paymentInstruments.map((paymentInstrument) => {
          if (paymentInstrument.uuid === applePayPaymentInstrument.uuid) {
            return (
              <Box pb={SizingMultiplier.md} key={paymentInstrument.uuid}>
                <ApplePayButton
                  onClick={onApplePayButtonClick}
                  variant={theme.palette.payments.applePayButtonVariant}
                />
              </Box>
            );
          }
          if (paymentInstrument.uuid === googlePayPaymentInstrument.uuid) {
            return (
              <Box pb={SizingMultiplier.lg} key={paymentInstrument.uuid}>
                <GooglePayButton
                  onClick={onGooglePayButtonClick}
                  variant={theme.palette.payments.googlePayButtonVariant}
                />
              </Box>
            );
          }
          // Cards not supported with "isListMode" prop
          return null;
        })}
      </>
    );
  }

  if (selectedPaymentInstrumentType === PAYMENT_INSTRUMENT_TYPE.APPLE_PAY) {
    return <ApplePayButton onClick={onApplePayButtonClick} variant={theme.palette.payments.applePayButtonVariant} />;
  }

  if (selectedPaymentInstrumentType === PAYMENT_INSTRUMENT_TYPE.GOOGLE_PAY) {
    return <GooglePayButton onClick={onGooglePayButtonClick} variant={theme.palette.payments.googlePayButtonVariant} />;
  }

  const isCardSelected = selectedPaymentInstrumentType === PAYMENT_INSTRUMENT_TYPE.CARD;
  return (
    <Button disabled={!isCardSelected} onClick={isCardSelected ? onCardPayButtonClick : undefined} fullWidth>
      <FormattedMessage
        id="payAtPump:payWithCardButtonTitle"
        defaultMessage="Pay"
        description="Pay at pump: pay with card button title"
      />
    </Button>
  );
};
