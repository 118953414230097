import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PayAtPumpTabBarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 -2 20 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.54 4.29L17 2.75L15.59 1.34C15.4968 1.24676 15.3861 1.1728 15.2642 1.12234C15.1424 1.07188 15.0119 1.04591 14.88 1.04591C14.7481 1.04591 14.6176 1.07188 14.4958 1.12234C14.3739 1.1728 14.2632 1.24676 14.17 1.34C14.0768 1.43324 14.0028 1.54393 13.9523 1.66575C13.9019 1.78757 13.8759 1.91814 13.8759 2.05C13.8759 2.18186 13.9019 2.31243 13.9523 2.43425C14.0028 2.55607 14.0768 2.66676 14.17 2.76L15.17 3.76L14.34 6.25C14.1645 6.77902 14.1398 7.34648 14.2687 7.88874C14.3977 8.43099 14.6751 8.92661 15.07 9.32L18.02 12.32V17C18.02 17.2652 17.9146 17.5196 17.7271 17.7071C17.5396 17.8946 17.2852 18 17.02 18C16.7548 18 16.5004 17.8946 16.3129 17.7071C16.1254 17.5196 16.02 17.2652 16.02 17V15C16.02 14.2044 15.7039 13.4413 15.1413 12.8787C14.5787 12.3161 13.8156 12 13.02 12H12V3C12 2.20435 11.6839 1.44129 11.1213 0.87868C10.5587 0.316071 9.79565 0 9 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H9C9.79565 20 10.5587 19.6839 11.1213 19.1213C11.6839 18.5587 12 17.7956 12 17V14H13C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15V17C14 17.7956 14.3161 18.5587 14.8787 19.1213C15.4413 19.6839 16.2044 20 17 20C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V7.83C20.0012 7.17295 19.8728 6.52211 19.6223 5.91469C19.3718 5.30728 19.004 4.75519 18.54 4.29ZM10 17C10 17.2652 9.89464 17.5196 9.70711 17.7071C9.51957 17.8946 9.26522 18 9 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10H10V17ZM10 8H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H9C9.26522 2 9.51957 2.10536 9.70711 2.29289C9.89464 2.48043 10 2.73478 10 3V8ZM18 9.42L16.46 7.88C16.3332 7.75068 16.2439 7.58934 16.2016 7.41322C16.1593 7.23709 16.1657 7.05279 16.22 6.88L16.73 5.34L17.12 5.74C17.6755 6.2947 17.9914 7.04503 18 7.83V9.42Z" />
    </SvgIcon>
  );
};
