import { useCallback } from 'react';

import { useCustomerPaymentInstrumentCreateMutation } from '@ocx/graphql';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { usePaymentProviderSDKBased, TokenizePaymentInstrumentParams } from '@ocx/data-payment-provider';
import { buildCreatePaymentInstrumentPayload } from '../../../modules/wallet/wallet.utils';

export const useCreatePaymentInstrument = () => {
  const paymentProvider = usePaymentProviderSDKBased();
  const [createPaymentInstrument] = useCustomerPaymentInstrumentCreateMutation({
    refetchQueries: ['getPaymentInstruments'],
  });

  return useCallback(
    async (input: TokenizePaymentInstrumentParams) => {
      const tokenizedData = await paymentProvider.tokenizePaymentInstrument(input);
      const { data } = await createPaymentInstrument({
        variables: {
          input: buildCreatePaymentInstrumentPayload(input, tokenizedData),
        },
      });

      return { paymentInstrumentId: data?.customerPaymentInstrumentCreate.uuid || null };
    },
    [paymentProvider, createPaymentInstrument],
  );
};
