import React from 'react';
import dollarCoinsIcon from '@ocx/buildtime-retailer-configuration/assets/dollars-coins-icon.svg';

type DollarsCoinsProps = {
  width?: number;
  height?: number;
};

export const DollarsCoins = (props: DollarsCoinsProps) => {
  return <img src={dollarCoinsIcon} alt="coins" {...props} />;
};
