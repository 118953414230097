import React from 'react';
import dollarCoinIcon from '@ocx/buildtime-retailer-configuration/assets/dollar-coin-icon.svg';

type DollarCoinProps = {
  width?: number;
  height?: number;
};

export const DollarCoin = (props: DollarCoinProps) => {
  const { width = 20, height = 20 } = props;
  return <img width={width} height={height} src={dollarCoinIcon} alt="coin" />;
};
