import { useEffect } from 'react';

import { Kount } from '@ocx/cap-kount';
import { logInDev } from '../lib/logger/logger';
import { useConfiguration } from '../modules/configuration/useConfiguration';

export const useKount = () => {
  const kountConfig = useConfiguration('kount');
  useEffect(() => {
    if (!kountConfig?.merchantId) {
      return;
    }
    Kount.init({
      merchantId: kountConfig.merchantId,
      collectorHostname: kountConfig?.collectorHostname,
      environment: kountConfig.environment,
    }).catch(logInDev);

    // Removed config.kount from hook dependencies
    // kount object can be reinitialised and triggered the hook second time
    // hook should not be triggered if merchantId/env/hostname did not changed
  }, [kountConfig?.collectorHostname, kountConfig?.merchantId, kountConfig?.environment]);
};
