import React from 'react';
import Payment from 'payment';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  TokenizeCreditPaymentInstrumentParams,
  TokenizePrepaidPaymentInstrumentParams,
} from '@ocx/data-payment-provider';
import { PaymentAccountType } from '@ocx/graphql';
import { ShowErrorFunc } from '../../components/TextField';
import { CardFormFields, CreditCardFormFields } from './vault-card.types';
import { parseExpiryDate } from '../../modules/wallet/wallet.utils';

/**
 * A more intuitive way to show validation errors on the Card Number input:
 * - If the input has never been touched or is valid, then do not show the error message.
 * - If the user is currently changing the card number, then do not show the error message.
 * - Otherwise, the value is invalid and input is blurred or focussed but unchanged, so show the error message.
 */
export const useShowCardNumberError = () => {
  /**
   * This function is called during a render phase; triggering a state change would throw a React error here (can't render inside a render.)
   * Instead, use a ref value to capture the previous input value for change detection.
   */
  const prevValue = React.useRef();

  return React.useCallback<ShowErrorFunc>(({ meta: { valid, active, touched }, value }) => {
    const changed = prevValue.current !== value;
    if (changed) {
      prevValue.current = value;
    }

    if (valid || !touched) {
      return false;
    }

    if (active && changed) {
      return false;
    }

    return true;
  }, []);
};

type FieldsToTokenizationInput<T extends CardFormFields['accountType']> = T extends PaymentAccountType.Credit
  ? TokenizeCreditPaymentInstrumentParams
  : T extends PaymentAccountType.Prepaid
  ? TokenizePrepaidPaymentInstrumentParams
  : never;

export function mapFormFieldsToTokenizationInput<T extends CardFormFields>(
  values: T,
): FieldsToTokenizationInput<T['accountType']> {
  switch (values.accountType) {
    case PaymentAccountType.Credit: {
      const { cardNumber, expirationDate, ...input } = values;
      const expiryDate = parseExpiryDate(values.expirationDate);
      const result = {
        ...(input as Omit<CreditCardFormFields, 'expirationDate'>),
        cardNumber: cardNumber.replace(/ /g, ''),
        cardType: Payment.fns.cardType(values.cardNumber).toUpperCase(),
        expirationDateMonth: expiryDate.month,
        expirationDateYear: expiryDate.year,
      } satisfies FieldsToTokenizationInput<typeof values.accountType>;
      return result as FieldsToTokenizationInput<T['accountType']>;
    }

    case PaymentAccountType.Prepaid:
      return values satisfies FieldsToTokenizationInput<typeof values.accountType> as FieldsToTokenizationInput<
        T['accountType']
      >;

    default:
      throw new Error('mapFormFieldsToTokenizationInput received unsupported account type');
  }
}
