import React from 'react';
import { Badge } from '@mui/material';

import { SurveysTabBarIcon } from '@ocx/ui-icons';

export interface ISurveysTabBarIconWithBadgeProps {
  totalCount: number;
}

export const SurveysTabBarIconWithBadge = ({ totalCount }: ISurveysTabBarIconWithBadgeProps) => {
  return (
    <Badge badgeContent={totalCount} color="error">
      <SurveysTabBarIcon />
    </Badge>
  );
};
