import Payment from 'payment';

import { convertDollarsToCents, formatPrice } from '@ocx-app/lib/formatters/formatPrice';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TokenizePaymentInstrumentParams, TokenizePaymentInstrumentReturns } from '@ocx/data-payment-provider';
import {
  CustomerPaymentInstrumentCreate,
  PaymentAccountType,
  PaymentInstrumentFragment,
  PaymentInstrumentType,
} from '@ocx/graphql';
import { PaymentInstrument, PaymentInstrumentOrder } from './wallet.types';
import { intl } from '../../lib/translation/TranslationProvider';

export const isDefaultPaymentInstrument = ({ isDefault }: PaymentInstrument): boolean => isDefault;

export const isApplePayFragment = (fragment: PaymentInstrumentFragment | null): boolean => {
  if (!fragment) {
    return false;
  }
  return (
    fragment.accountType === PaymentAccountType.ApplePay || fragment.paymentType === PaymentInstrumentType.ApplePay
  );
};

export const isGooglePayFragment = (fragment: PaymentInstrumentFragment | null): boolean => {
  if (!fragment) {
    return false;
  }
  return (
    fragment.accountType === PaymentAccountType.GooglePay ||
    (!!fragment.paymentType &&
      [PaymentInstrumentType.GooglePay, PaymentInstrumentType.GoogleWallet].includes(fragment.paymentType))
  );
};

export type SanitizePaymentInstrumentFragmentContext = {
  availableAccountTypes: Readonly<PaymentAccountType[]>;
};
export const sanitizePaymentInstrumentFragment = (
  fragment: PaymentInstrumentFragment,
  context: SanitizePaymentInstrumentFragmentContext,
): PaymentInstrument => {
  const canBeUsedToPay = context.availableAccountTypes.includes(fragment.accountType);

  return {
    alias: fragment.alias,
    accountType: fragment.accountType,
    paymentType: fragment.paymentType,
    accountNumber: fragment.accountNumber,
    providerNickName: fragment.providerNickName,
    last4: fragment.displayNumber,
    address: fragment?.address?.zipCode
      ? {
          zipCode: fragment.address.zipCode,
        }
      : null,
    uuid: fragment.uuid,
    displayName: fragment.displayNumber || '',
    balance: fragment.balance,
    isDefault: !!fragment.isDefault,
    canBeDeleted: true,
    canBeUsedToPay,
    order: canBeUsedToPay ? PaymentInstrumentOrder.Default : PaymentInstrumentOrder.Disabled,
  };
};

const FIRST6_LENGTH = 6;
const LAST4_LENGTH = 4;
export const getCardNumberAttributes = (cardNumber: string) => {
  const first6 = cardNumber.slice(0, FIRST6_LENGTH);
  const last4 = cardNumber.slice(cardNumber.length - LAST4_LENGTH);
  return { first6, last4 };
};

export const buildCreatePaymentInstrumentPayload = (
  input: TokenizePaymentInstrumentParams,
  tokenizationData: TokenizePaymentInstrumentReturns,
): CustomerPaymentInstrumentCreate => {
  const { first6, last4 } = getCardNumberAttributes(input.cardNumber);

  if (input.accountType === PaymentAccountType.Prepaid) {
    return {
      accountType: tokenizationData.accountType,
      paymentType: PaymentInstrumentType.Prepaid,
      binRange: first6,
      last4,
      nonce: tokenizationData.nonce,
      fiservOAuthToken: tokenizationData.oAuthToken,
      name: null,
      alias: input.alias,
      accountNumber: null,
      encryptedPaymentInstrumentDetails: null,
      // Passing `setAsDefault: null` persists default logic on API side
      setAsDefault: null,
      externalRequestData: null,
    };
  }

  return {
    accountType: tokenizationData.accountType,
    last4,
    binRange: first6,
    nonce: tokenizationData.nonce,
    paymentType: tokenizationData.paymentInstrumentType,
    fiservOAuthToken: tokenizationData.oAuthToken,
    name: null,
    alias: input.alias || null,
    accountNumber: null,
    address: {
      zipCode: input.zipCode,
      alias: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      geoLocation: null,
    },
    expiration: {
      expirationMonth: input.expirationDateMonth,
      expirationYear: input.expirationDateYear,
    },
    encryptedPaymentInstrumentDetails: null,
    // Passing `setAsDefault: null` persists default logic on API side
    setAsDefault: null,
    externalRequestData: tokenizationData.externalRequestData ?? null,
  };
};

const getPaymentInstrumentDisplayName = (paymentInstrument: PaymentInstrument) => {
  const { paymentType, last4, displayName, alias } = paymentInstrument;

  if (paymentType === PaymentInstrumentType.Prepaid && last4) {
    return intl.formatMessage(
      {
        id: 'payments:prepaid-payment-instrument:name',
        defaultMessage: 'Gift Card - {last4}',
        description: 'The name for prepaid payment instrument ',
      },
      {
        last4,
      },
    );
  }

  if (last4) {
    return `•••• ${last4}`;
  }

  return displayName || alias || 'Unknown';
};

// TODO: start using all over the project
// TODO: add typing
export const getPaymentInstrumentListItemInfo = (paymentInstrument: PaymentInstrument) => {
  const { balance } = paymentInstrument;

  const paymentInstrumentName = getPaymentInstrumentDisplayName(paymentInstrument);

  const paymentInstrumentBalance =
    typeof balance === 'number'
      ? formatPrice(convertDollarsToCents(balance), {
          displayCentsWhenLessThanDollar: true,
        })
      : null;

  return {
    displayName: paymentInstrumentName,
    formattedBalance: paymentInstrumentBalance,
  };
};

/**
 * Splits a string containing `MM/YY` into 'month' and 'year' fields.
 */
export const parseExpiryDate = (expiryDate: string) => {
  const { month, year } = Payment.fns.cardExpiryVal(expiryDate);
  return {
    month: String(month).padStart(2, '0'),
    year: String(year),
  };
};
