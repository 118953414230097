import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Stack } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PointsChange } from '@ocx-app/components/PointsChange/PointsChange';
import { PointsStrategy } from '@ocx/graphql';
import { SizingMultiplier, Typography } from '@ocx/ui';
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { PointsCoins } from '@ocx/ui-icons';

import { ReceiptPointChangeType } from '../../../../receipts.types';
import { TransactionIconBox } from '../../../transaction-icon-box';
import { TwoColsTypography } from '../../../two-cols-typography';
import { messages } from '../../receipt-loyalty-card.messages';

export type PointChangeItemProps = {
  pointsDiff: number;
  appliedOffersNumber: number;
  type: ReceiptPointChangeType;
};

const ICON_SIZE = 24;

const RECEIPT_LINE_ITEMS: Record<ReceiptPointChangeType, { text: MessageDescriptor; icon?: React.ReactNode }> = {
  spent: {
    text: messages['receipt:loyalty-card:points-spent'],
    icon: <PointsCoins width={ICON_SIZE} />,
  },
  earned: {
    text: messages['receipt:loyalty-card:points-earned'],
    icon: <PointsCoins width={ICON_SIZE} />,
  },
  refund: {
    text: messages['receipt:loyalty-card:points-refund'],
    icon: <FileDownloadOutlinedIcon width={ICON_SIZE} />,
  },
};

export const PointChangeItem = (props: PointChangeItemProps) => {
  const { type, pointsDiff, appliedOffersNumber } = props;
  const pointsStrategy =
    (useConfiguration('loyaltyProgram.pointsStrategy') as Exclude<PointsStrategy, PointsStrategy.NotUsed>) ??
    PointsStrategy.Points;

  return (
    <Stack direction="row" spacing={SizingMultiplier.sm} alignItems="center">
      <TransactionIconBox {...(type !== 'refund' && { backgroundColor: 'Receipts.currencyBackground' })}>
        {RECEIPT_LINE_ITEMS[type].icon}
      </TransactionIconBox>
      <Box flex={1}>
        <TwoColsTypography
          title={<FormattedMessage {...RECEIPT_LINE_ITEMS[type].text} />}
          value={
            <PointsChange
              pointsStrategy={pointsStrategy}
              points={pointsDiff}
              iconPosition="right"
              typographyProps={{ variant: 'subtitle1' }}
            />
          }
          variant="subtitle1"
        />

        {appliedOffersNumber > 0 && (
          <Typography variant="body2" color={'text.disabled'} mt={SizingMultiplier.xs}>
            <FormattedMessage {...messages['receipt:loyalty-card:offers-applied']} values={{ appliedOffersNumber }} />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
